@import "../../index";

.posts-breadcrumbs {
  margin-top: 45px;
  text-transform: capitalize;

  h2 {
    font-size: 20px;
    border-left: 3px solid #F4976C;
    padding: 0 15px;
    margin-bottom: 15px;
  }
}

.posts-page {
  padding: 0 15%;

  .posts-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 30px;

    .nth_article {
      min-width: 184px;
      width: calc((100% - 90px) / 4);
    }
  }
}


@media screen and (max-width: 1580px) {
  .posts-page {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 1350px) {
  .posts-page {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

@media screen and (max-width: 1024px) {
  .posts-page {
    padding-left: 3%;
    padding-right: 3%;
    .posts-wrapper {
      .nth_article {
        width: calc((100% - 90px) / 3);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .posts-page {
    padding-left: 16px;
    padding-right: 16px;

    .posts-wrapper {
      flex-direction: column;
      align-items: center;

      .nth_article {
        width: 100%;
        min-height: 300px;
      }
    }
  }
}