@import "../../index";

.blogs-and-news {
  width: 100%;
  display: flex;
  gap: 30px;
  padding-top: 80px !important;
  padding-bottom: 80px !important;
  background-color: #f8feff;

  .category {
    background-color: $open-color;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    box-shadow: 0 0 5px $gray;
    transition: 0.25s;

    &:hover {
      box-shadow: 0 0 15px $gray;
    }

    .category-section {
      gap: 15px;
    }

    .view-all {
      text-align: center;

      a {
        text-decoration: none;
        color: $black;
        font-weight: bold;
        transition: 0.25s;

        span {
          color: $site-color-text;
          transition: 0.25s;

        }

        &:hover {
          color: $site-color-text;

          span {
            color: $black;
          }
        }
      }

    }
  }
}

.articles_section {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 50px 0;
  width: 100%;

  .nth_article {
    border-radius: 15px;
    overflow: hidden;
    max-width: 350px;
    min-height: 386px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    border: 1px solid $gray;

    .new-indicator {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
      background-color: $site-color-text;
      padding: 7.5px;
      color: $open-color;
      border-radius: 5px;
      z-index: 1;
    }

    .image {
      width: 100%;
      height: 100%;
      max-height: 200px;
      transition: 0.25s;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1024px) {
        min-height: 150px;
      }
    }

    .article_nth_info {

      border: 1px solid var(--color-secondary);
      border-radius: 0 0 15px 15px;
      border-top: none;
      padding: 10px;

      .date {

        margin-bottom: 10px;
        color: gray;
      }

      .text {
        font-size: 18px;
        color: $black;
        font-weight: 600;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        height: 38px;
        -webkit-box-orient: vertical;
      }

      .description {
        font-size: 14px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 32px;
      }

      @media screen and (max-width: 768px) {
        .text {
          font-size: 14px;
        }
      }
    }

    .postBtn {
      position: relative;
      text-align: center;
      margin-top: 20px;
      color: $open-color;
    }


    button {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 14px 20px;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      letter-spacing: .1em;
      color: $open-color;
      background-color: $static-btn-color;
      border: 1px solid $gray;
      transition: 0.3s;
      position: relative;

      &:hover {
        background-color: var(--orange-light);
        color: $black;
      }
    }

    &:hover {
      .image {
        transform: scale(1.05);
      }
    }

  }


}

@media screen and (max-width: 1024px) {
  .blogs-and-news {
    flex-direction: column;

    .category-section {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 768px) {
  .articles_section {
    flex-direction: column;

    .nth_article {
      max-width: unset;
      width: 100%;

      .image {
        height: 100px !important;
        width: 100% !important;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover !important;
        }
      }
    }
  }

  .blogs-and-news {
    padding-top: 50px !important;

    .category-section {
      flex-direction: column;
    }
  }

}
