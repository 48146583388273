//-->
//$black:#000000 ;
//$site-color: #009ba0;
//$site-color-text: #009ba0;
//$site-color-lite: #2be4ea;
//$answer:#D1E8E2 ;
//$site-color-transparent: rgba(0, 155, 160, 0.57);
//$back-ground-lite: #FFCB9A;
//$header-default: rgba(255, 203, 154, 0.16);
//$header-scroll: #e6b88c;
//$area-transparent: rgba(103, 93, 83, 0.7);
//$area-nav: rgba(255, 221, 186, 0.12);
//$area-hover: rgba(255, 232, 209, 0.32);
//$static-btn-color: #323237;
//$gray-lite: #ededed;


//$black:#000000 ;
//$site-color: #007189;
//$site-color-text: #007189;
//$site-color-lite: #007189;
//$answer: #88BDBC;
//$site-color-transparent: rgba(37, 78, 88, 0.58);
//$back-ground-lite: #88BDBC;
//$header-default: rgba(136, 189, 188, 0.21);
//$header-scroll: #5e8484;
//$area-transparent: rgba(136, 189, 188, 0.24);
//$area-hover: rgba(136, 189, 188, 0.5);
//$area-nav: rgba(255, 221, 186, 0.12);
//$static-btn-color: #323237;
//$gray-lite: #ededed;


//
//$black:#000000 ;
//$site-color: #86C232;
//$site-color-text: #86C232;
//$site-color-lite: #86C232;
//$answer: #b6ccda;
//$site-color-transparent: rgba(134, 194, 50, 0.4);
//$back-ground-lite: #949ea5;
//$header-default: rgba(148, 158, 165, 0.12);
//$header-scroll: rgb(116, 124, 128);
//$area-transparent: rgba(148, 158, 165, 0.71);
//$area-hover: rgba(160, 166, 170, 0.86);
//$area-nav: rgba(255, 221, 186, 0.12);
//$static-btn-color: #323237;
//$gray-lite: #ededed;


//                               ->
$black: #303C6C;
$site-color: #F4976C;
$site-color-text: #ed6f37;
$site-color-lite: #F4976C;
$answer: #e6ffff;
$site-color-transparent: rgba(244, 151, 108, 0.42);
$back-ground-lite: #B4DFE5;
$header-default:#367b7f66;
$header-scroll:#305b62;
$area-transparent: rgba(108, 197, 210, 0.56);
$area-nav: rgba(255, 221, 186, 0.12);
$area-hover: rgba(103, 185, 197, 0.41);
$static-btn-color: #323237;
$gray-lite: #e1e1e1;
$gray: #bababa;
$open-color: #FFFFFF;
