@import "../../index";

.page-main {
  min-height: 100vh;
}

.empty-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
}

.related_articles {
  padding-top: 50px !important;
  float: left;

  .articles_section {
    padding-top: 20px;
  }

  .related-title {
    color: $black;
  }
}

.post-comments {
  max-width: 750px;
  min-width: 250px;
  width: 100%;
  margin-bottom: 50px;

  .comment-item {
    border: 1px solid $gray;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .head {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 10px;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 28px;
      border: 1px solid $gray;
      color: #FFFFFF;
      text-transform: uppercase;
    }

    .name {
      color: $black;
    }

    .date {
      color: gray;
      margin-inline-start: auto;
    }
  }
  .view-all-comments{
    text-align: center;
    color: $black;
    cursor: pointer;
    font-weight: bold;
  }
}

.replay-comment {
  float: left;
  width: 100%;
  padding-bottom: 50px !important;

  .leave-a-reply {
    max-width: 750px;
    min-width: 250px;
    width: 100%;

    .message-area {
      margin: 20px 0;

      p {
        margin-bottom: 5px;
      }

      textarea {
        width: 100%;
        padding: 10px 15px;
        min-height: 100px;
        border: 1px solid $gray;

        &.error {
          border-color: red;
        }
      }
    }

    .fields {
      display: flex;
      gap: 15px;
    }

    .input-field {
      margin-bottom: 10px;
      width: 100%;

      .label {
        margin-bottom: 5px;
      }

      input {
        background: transparent;
        display: inline-block;
        min-height: 40px;
        width: 100%;
        font-size: 14px;
        line-height: 1.8;
        padding: 6px 12px;
        color: #333;
        border: 1px solid $gray;
        border-radius: 3px;

        &.error {
          border-color: red;
        }

        &::placeholder {
          color: $static-btn-color;
        }
      }

      .error {
        height: 16px;
        font-size: 14px;
        color: red;
      }
    }

    .save-author{
      cursor: pointer;
    }

    .submit-comment {
      display: flex;
      justify-content: flex-end;

      button {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 14px 20px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        background-color: #323237;
        border: 1px solid #bababa;
        transition: 0.3s;
        position: relative;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .message {
      transition: 1.6s !important;
      opacity: 0;
      height: 26px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #FFFFFF;

      &.success {
        transition: 0.6s;
        opacity: 1;
        color: green;
      }

      &.error {
        opacity: 1;
        color: red;
      }
    }
  }

}

.article_page {
  padding-bottom: 150px;
  margin-top: 30px;

  .post-info {
    float: right;
  }

  .title {
    text-align: center;
    color: $black;
    margin-bottom: 20px;
  }


  .main_image {
    width: 50%;
    min-width: 300px;
    display: inline;
    float: left;
    margin-right: 30px;

    img {
      width: 100%;
      object-fit: contain;
    }

    .post-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 15px;
      padding: 10px 0;
      float: left;

      .to-posts {
        color: $black;
        font-weight: bold;
      }

      .comments-count {
        color: black;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .article_info {
    display: inline;
  }

  @media screen and (max-width: 768px) {
    .main_image {
      margin-right: 15px;
    }
  }
  @media screen and (max-width: 591px) {
    .title {
      font-size: 14px;
    }
    .main_image {
      margin-right: 15px;
      width: 100%;
      height: 200px;
      display: block;
      float: unset;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .article_info {
      display: block;
      margin-top: 20px;
    }
  }
}