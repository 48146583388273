@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5u96yv');
  src:  url('fonts/icomoon.eot?5u96yv#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5u96yv') format('truetype'),
    url('fonts/icomoon.woff?5u96yv') format('woff'),
    url('fonts/icomoon.svg?5u96yv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e901";
}
.icon-checkmark-outline:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-user:before {
  content: "\e971";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
