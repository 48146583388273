@import "src/index";

.contact-main {
  padding: 50px 18.5%;
  display: flex;
  gap: 2%;
  min-height: 100vh;
  justify-content: space-between;

  .contact-about {
    width: 48%;

    .title {
      font-size: 20px;
      border-left: 3px solid $site-color;
      padding: 0 15px;
      margin-bottom: 15px;
    }

    .content p {
      margin-bottom: 30px;
      line-height: 30px;
    }

    .contacts {
      .contact {
        a {
          width: 100%;
          display: flex;
          gap: 15px;
          align-items: center;
          background-color: $header-scroll;
          margin-bottom: 20px;
          border-radius: 10px;
          padding: 20px;
          transition: 0.3s;

          .icon {
            font-size: 24px;
            color: $site-color;
          }

          p {
            &:first-of-type {
              color: $gray;
              font-width: bold;
              font-size: 18px;
              margin-bottom: 5px;
            }

            &:last-of-type {
              color: $open-color;
            }
          }

          &:hover {
            box-shadow: 0 0 15px $header-scroll;
            transform: scale(1.01);
          }
        }

      }
    }
  }

  .contact-form {
    width: 48%;

    form {
      display: flex;
      flex-direction: column;

      .input-field {
        margin-bottom: 10px;
        width: 100%;

        .label {
          margin-bottom: 10px;
        }

        input {
          background: transparent;
          display: inline-block;
          min-height: 40px;
          width: 100%;
          font-size: 14px;
          line-height: 1.8;
          padding: 6px 12px;
          color: #333;
          border: 1px solid $gray;
          border-radius: 3px;

          &.error {
            border-color: red;
          }

          &::placeholder {
            color: $static-btn-color;
          }
        }

        .error {
          height: 16px;
          font-size: 14px;
          color: red;
        }
      }

      .message-area {
        width: 100%;

        textarea {
          width: 100%;
          min-height: 200px;
          padding: 12px;
          margin-top: 10px;
          font-size: 14px;
        }
      }
      .message{
        transition: 1.6s !important;
        opacity: 0;
        height: 26px;
        margin-top: 10px;
        font-size: 20px;
        color: #FFFFFF ;
        &.success{
          transition: 0.6s;
          opacity: 1;
          color: green ;
        }
        &.error{
          opacity: 1;
          color: red;
        }
      }

      button {
        margin-top: 20px;
        background-color: $static-btn-color;
        color: $gray-lite;
        border: none;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 14px 20px;
        border: 0;
        cursor: pointer;
        width: max-content;

        &:disabled {
          opacity: 0.5;
          cursor: initial;
        }
      }
    }
  }
}


@media screen and (max-width: 1580px) {
  .contact-main {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 1350px) {
  .contact-main {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

@media screen and (max-width: 1024px) {
  .contact-main {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 768px) {
  .contact-main {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    .contact-about{
      width: 100%;
      margin-bottom: 20px;
      .contacts {
        .contact {
          a {
            padding: 10px;
          }
        }
      }
    }
    .contact-form{
      width: 100%;
      margin-bottom: 50px;
    }
  }
}