@import "../../index";
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.top-panel {
  padding: 15px 21%;
  display: flex;
  gap: 10%;
  height: 48px;
}

.top-panel, .mobile-menu.active {
  .info {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    gap: 5px;

    .icon {
      transition: 0.6s;
      color: $site-color;
    }

    a {
      color: $black;
      font-weight: bold;
    }
  }
}

header {
  height: 74px;
  background-color: $header-default;
  padding: 0 18.5%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  transition: 0.3s;

  &.blur {
    backdrop-filter: blur(10px);
    background-color: $header-scroll;

    .nav-item {
      background-color: inherit;
    }
  }

  .logo {
    padding: 5px 0;
    transition: 0.3s;

    &:hover {
      filter: brightness(0.75);
    }

    img {
      height: 60px;
      width: 230px;
    }
  }

  nav {
    display: flex;

    .nav-item {
      padding: 0 15px;
      transition: 0.6s;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: $site-color-text;
        font-weight: bold;
        position: relative;
        padding: 2.5px 0;
      }

      a::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: $site-color;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
      }

      a:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }

      /* Presentational Styles */
      body {
        display: grid;
        font-family: 'Poppins', sans-serif;
        font-size: 27px;
        font-weight: 700;
        height: 100vh;
        place-items: center;
      }
    }
  }

  .menu-burger {
    display: none;
  }

  .mobile-menu {
    display: none;
  }
}

.to-top {
  width: 44px;
  height: 44px;
  border-radius: 2px;
  background-color: $site-color-transparent;
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  right: 20px;
  bottom: 20px;
  opacity: 0;
  transition: 0.6s;
  cursor: pointer;

  p {
    margin-bottom: 5px;
  }

  &.available {
    opacity: 1;

    &:hover {
      background-color: $site-color;
    }
  }
}

footer {
  background-color: #1b1b1b;

  .rights {
    text-align: center;

    p {
      color: #FFFFFF;
      font-size: 12px;
      padding: 15px 0;
    }
  }
}

@media screen and (max-width: 1580px) {
  header, .top-panel {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 1350px) {
  header, .top-panel {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

@media screen and (max-width: 1024px) {
  .top-panel {
    padding-left: 3%;
    padding-right: 3%;
  }
  header {
    padding-left: 3%;
    padding-right: 3%;
    align-items: center;

    nav {
      display: none;
    }

    .menu-burger {
      display: flex;
      align-items: center;
      padding-right: 15px;
      cursor: pointer;

      .icon-menu {
        color: $site-color;
        font-size: 20px;
      }

      .close {
        position: fixed;
        z-index: 9;
        top: 15px;
        right: 5%;
        transform: rotate(45deg);
        font-size: 42px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .bg-phone {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgb(26 39 41 / 57%);
    }

    .mobile-menu {
      width: 0;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 5;
      transition: 0.3s;
      overflow: hidden;
      display: block;
      background-color: #FFFFFF;
      border-bottom: 1px solid $gray-lite;
      box-shadow: 0 0 15px $header-scroll;

      &.active {
        width: 80vw;
      }

      .menu-logo {
        padding: 30px 16px;

        img {
          max-width: 168px;
        }
      }

      ul {
        li {
          a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 16px;
            border-bottom: 1px solid $gray-lite;
            color: $black;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top-panel {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid #f1f1f1;

    .email-info {
      display: none;
    }
  }
  header {
    padding-left: 16px;
    padding-right: 16px;

    .logo {
      img {
        width: 186px;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  header {
    padding-left: 16px;
    padding-right: 16px;

    .mobile-menu.active {
      width: 100vw;
    }

    .menu-burger .close {
      color: $black;
      right: 2%;
    }
  }
}